<template>
    <div class="builder" :class="progressBarClass">
        <CustomNoty></CustomNoty>
        <div class="builder__top-bar">
            <TopBar />
        </div>
        <div class="builder__progress-bar" :class="progressBarClass"></div>
        <StretchContent
            class="builder__container"
            :stretchOptions="stretchOptions"
            @onResizingContent="handlerUpdPreview"
            @onResizingContentEnd="handlerResizeEnd"
        >
            <template v-slot:sidebarLeft>
                <div v-show="!isPreviewModeFullscreen" class="builder__left">
                    <SideBarLeft />
                </div>
            </template>
            <template v-slot:main>
                <div class="builder__preview" :class="{ '--resizing': isResizing }">
                    <Preview v-if="isReady" :route="pageRoute" :resizeValue="resizeValue" />
                </div>
            </template>
            <template v-slot:sidebarRight>
                <div v-show="!isPreviewModeFullscreen" class="builder__right">
                    <builder-right-templates></builder-right-templates>
                    <SideBarRight></SideBarRight>
                </div>
            </template>
        </StretchContent>
    </div>
</template>

<script>
import EventEmitter from '@/helpers/eventEmitter.ts';
import { HistoryTypes } from '@/store/Types.js';
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'Builder',
    components: {
        BuilderRightTemplates: () => import('@/components/builder/optionList/builder-right-templates'),
        TopBar: () => import('@/components/builder/topbar'),
        // SideBarLeft: () => import('@/components/builder/sidebar-left'),
        SideBarLeft: () => import('@/components/builder/sidebarLeft/index.vue'),
        SideBarRight: () => import('@/components/builder/sidebar-right'),
        Preview: () => import('@/components/builder/preview.vue'),
        CustomNoty: () => import('@/components/common/CustomNoty.vue'),
        StretchContent: () => import('@/components/builder/stretch-content/stretch-content.vue'),
    },
    data() {
        return {
            progressBarClass: '',
            isReady: false,
            showTemplates: false,
            resizeValue: 0, // костыль для отслеживания изменения масштаба iframe в Preview
            stretchOptions: [
                {
                    slotName: 'sidebarLeft',
                    side: 'right',
                    maxWidth: 600,
                },
                { slotName: 'main' },
                {
                    slotName: 'sidebarRight',
                    side: 'left',
                    maxWidth: 600,
                },
            ],
            isResizing: false,
        };
    },
    computed: {
        pageRoute() {
            return {
                type: this.$route.params.type,
                id: this.$route.params.id,
                categoryId: this.$route.params.categoryId,
            };
        },
        routeActiveItem() {
            return this.$route.params.activeItem;
        },
        ...mapState('builder', {
            currentPage: 'currentPage',
            settings: 'settings',
            isNewPage: 'isNewPage',
        }),
        ...mapGetters('builder', {
            isPreviewModeFullscreen: 'isPreviewModeFullscreen',
        }),
    },
    created() {
        const locale = localStorage.getItem('locale');
        if (locale) this.$i18n.locale = locale;
        EventEmitter.on('progress-bar__in-progress', () => {
            this.progressBarClass = 'in-progress';
        });
        EventEmitter.on('progress-bar__done', () => {
            this.progressBarClass = 'done';
            setTimeout(() => {
                this.progressBarClass = '';
            }, 300);
        });
        this.loadClipboard();
        window.onfocus = () => {
            this.loadClipboard();
        };
    },
    watch: {
        pageRoute: {
            immediate: true,
            handler(newPageRoute) {
                this.onPageSwitch(newPageRoute);
            },
        },
    },
    methods: {
        handlerUpdPreview(value) {
            this.isResizing = true;
            this.resizeValue = value;
        },
        handlerResizeEnd() {
            this.isResizing = false;
        },
        ...mapActions('builder', {
            loadPage: 'loadPage',
            loadSettings: 'loadSettings',
            updateActiveItem: 'updateActiveItem',
            updateIsNewPage: 'updateIsNewPage',
            addHistoryPoint: 'addHistoryPoint',
        }),
        ...mapActions('clipboard', {
            loadClipboard: 'loadClipboard',
        }),
        async onPageSwitch(newPageRoute) {
            this.isReady = false;
            try {
                await Promise.all([this.loadPage(newPageRoute), this.loadSettings()]);
            } catch (err) {
                console.error(err);
            }
            this.isReady = true;
            // определяем это новая страница или уже имеющаяся
            const isNew =
                (this.$route.params.type === 'textpage' || this.$route.params.type === 'catalog') &&
                !this.$route.params.id;
            this.updateIsNewPage(isNew);
            this.$store.dispatch(HistoryTypes.actions.RESET_HISTORY);
            this.addHistoryPoint();
            if (this.$route.params.type === 'homepage' && this.routeActiveItem === 'settings') {
                this.updateActiveItem({ type: 'settings', data: cloneDeep(this.settings) });
            } else if (this.$route.params.type === 'cart' || this.$route.params.type === 'checkout') {
                this.updateActiveItem({
                    type: 'settings',
                    data: cloneDeep(this.settings),
                });
            } else {
                this.updateActiveItem({
                    type: 'page',
                    data: this.currentPage?.id,
                });
            }

            this.showDisabledPageContent();
        },
        showDisabledPageContent() {
            if (this.currentPage?.enabled || !this.currentPage?.sections) return;
            setTimeout(() => {
                console.log('addSection');
                this.currentPage.sections.forEach((section) => {
                    EventEmitter.presets.public.addSection(section.data);
                });
            }, 1000);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.builder {
    $height: calc(100vh - 64px);
    $sidebar-width: 300px;
    height: 100vh;
    padding: 0;
    overflow: hidden;

    &__container {
        display: flex;
        height: $height;
    }
    &__left {
        display: flex;
        flex-direction: column;
        width: var(--width, $sidebar-width);
        height: $height;
    }

    &__preview {
        flex: auto;
        height: $height;
        padding: $spacer * 5 $spacer * 8;
        background: var(--v-background-base);
        &.--resizing {
            pointer-events: none;
        }
    }

    &__progress-bar {
        position: absolute;
        left: 0;
        top: 64px;
        width: 0;
        height: 2px;
        background: var(--v-primary-base);
        z-index: 2;
        transition: all 0.05s linear;
        opacity: 0;

        &.in-progress {
            width: 90%;
            opacity: 1;
            transition-timing-function: cubic-bezier(0.26, 0.605, 0.355, 0.69);
            transition-duration: 2s;
        }

        &.done {
            width: 100%;
            transition-duration: 0.2s;
            opacity: 1;
        }
    }

    &__right {
        width: var(--width, $sidebar-width);
        height: $height;
    }
}
</style>
